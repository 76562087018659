<template>
<div>
<section class="container-fluid p-0" style="background-color: #fff;">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6 bg-login-2 d-flex" :style="{'background-image': `url(${required('bg-login-qs-admin.jpg')})`}">
                <div class="align-self-end p-bg-login w-100 text-center text-lg-left">
                    <div class="d-lg-none d-block pb-2">
                        <img src="~@/assets/img/logo-gsuitew.png" alt="Qsuite" style="max-height: 35px;">
                    </div>
                    <h1 class="text-white title-maisconexoes">
                        <b>Mais conexões<br>de um só lugar!</b>
                    </h1>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 align-self-center">
                <div class="col-12 p-logo-login pr-0 pb-1 text-center d-none d-lg-block">
                    <img src="~@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 40px;">
                </div>
                <div class="col-12 text-center pt-3 pb-1">
                    <p>Fazer login para prosseguir para o QSuíte</p>
                </div>
                <div class="col-12 col-md-10 col-lg-8 mx-auto pb-4">
                        <form class="needs-validation" novalidate @submit.prevent="login">
                        <div class="col-12">
                            <label class="d-none">E-mail </label>
                            <input type="email" class="form-control inputs-login" placeholder="exemplo@exemplo.com" v-model="email" v-validate="'required|email'">
                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu e-mail.</div>
                        </div>
                        <div class="col-12 pt-2">
                            <label class="d-none">Senha </label>
                            <input type="password" class="form-control inputs-login" id="passtext" placeholder="********" v-validate="'required|min:6'" v-model="password">
                        </div>
                        <b-form-invalid-feedback :state="!error">
                           Email ou senha incorretos.
                        </b-form-invalid-feedback>
                        <div class="col-12 text-right pt-2 d-none">
                            <router-link :to="{name: 'Recovery'}" title="Senha" class="font-12 a-link-1">Esqueci minha senha?</router-link>
                        </div>
                        <div class="col-12 text-center pt-3">
                            <a href="qs-admin.php">
                                <button type="submit" class="btn btn-info button-login-admin">Entrar</button>
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<BaseLoginFooter />
</div>
</template>
<script>
export default {
    data() {
        return {
            email: null,
            password: null,
            error: false
        };
    },
    methods:{
        required(image){
            return require(`@/assets/img/${image}`); 
        },
        login(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                
                let data = {
                    username: this.email,
                    password: this.password,
                };

                this.$store
                .dispatch("loginRequest", data)
                .then(() => {
                })
                .catch((err) => {
                    this.error = true;
                });
                }
            });
        }
    }
}
</script>